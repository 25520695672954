<template>
  <div class="msg-top"></div>
  <div class="msg-wrap">
    <div class="menu">
      <div>
        <!-- 消息中心 -->
        <span class="bread"
          >{{ $t("mine.message_center")
          }}<em class="totals"
            >{{ $t("mine.total_of", 1) }} <em class="blue">{{ Total }}</em>
            {{ $t("mine.total_of", 2) }}</em
          ></span
        >
        <!-- 共有 条 -->
      </div>
      <div class="funcs">
        <span @click="selectAll" v-show="isEdit">{{
          isAll ? $t("CM_UnSelectAll") : $t("CM_SelectAll")
        }}</span>
        <!-- "全不选" : "全选" -->
        <span @click="toDel" v-show="isEdit">{{ $t("CM_Delete") }}</span>
        <!-- 删除 -->
        <span @click="isEdit = !isEdit">{{
          isEdit ? $t("BN_Comm_Completed") : $t("BN_Comm_Edit")
        }}</span>
        <!-- "完成" : "编辑" -->
      </div>
    </div>
    <div class="list" v-if="dataList.length > 0">
      <div class="item" v-for="(item, index) in dataList" :key="index">
        <div class="top">
          <span class="title">
            {{ getResourceName(item.taskType) }}
            <span :class="{ dot: item.readTime === 0 }"></span>
          </span>
          <a-checkbox v-model:checked="item.isDel" v-if="isEdit"></a-checkbox>
        </div>
        <div class="date">{{ moment(item.createTime * 1000).fromNow() }}</div>
        <div class="detail" @click="clickJump(item)">
          <div class="content">
            <template v-for="(str, i) in openDataRender(item.text)">
              <template v-if="str.slice(0, 2) === '$['">
                <OpenData type="userName" :openid="str.slice(2, -1)" :key="i" />
              </template>
              <template v-else>
                {{ str }}
              </template>
            </template>
          </div>
          <a href="javascript:;">{{ $t("view_detail") }}</a>
          <!-- 查看详情 -->
        </div>
      </div>
      <a-pagination
        v-model:current="searchParams.page"
        :defaultPageSize="searchParams.pageSize"
        :page-size-options="pageSizeOptions"
        :total="Total"
        show-size-changer
        @change="changePage"
        @showSizeChange="showSizeChange"
      />
    </div>
    <div
      :style="{
        textAlign: 'center',
        marginTop: '24px',
        height: '32px',
        lineHeight: '32px',
        color: '#BCBCBC',
      }"
      v-if="dataList.length <= 0"
    >
      <a-empty style="padding: 150px 0" />
    </div>
  </div>
  <div class="msg-top"></div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs } from "@vue/reactivity";
import { msgList, msgRead, batchDelMsg } from "@/api/user";
import moment from "moment";
import ls from "@/utils/local-storage";
import { createVNode, getCurrentInstance } from "@vue/runtime-core";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getActivityInfo } from "@/api/activity";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      searchParams: {
        page: 1,
        pageSize: 10,
      },
      dataList: [],
      loading: true,
      Total: 0,
      isEdit: false,
      isAll: false,
    });

    const reload = () => {
      state.searchParams.page = 1;
      state.loading = true;
      getList();
    };

    const getList = () => {
      msgList(state.searchParams).then((res) => {
        if (res.ret === 0) {
          state.dataList = res.data.list;
          state.Total = res.data.totals;
          // 加载状态结束
          state.loading = false;
        }
      });
    };

    const changePage = (page, pageSize) => {
      state.searchParams.page = page;
      state.searchParams.pageSize = pageSize;
      getList();
    };

    const showSizeChange = (current, pageSize) => {
      state.searchParams.page = current;
      state.searchParams.pageSize = pageSize;
      getList();
    };

    const getResourceName = (type) => {
      if (type == 0 || type == 1) {
        return $t("MN_TrainingProject_MHome");
        // 培训项目
      } else if (type == 2) {
        return $t("daily_exam");
        // 每日一练
      } else if (type == 3) {
        return $t("Pub_Tab_Knowledge");
        // 知识
      } else if (type == 4) {
        return $t("CM_Curriculum");
        // 课程
      } else if (type == 5) {
        return $t("cm_subject");
        // 课程专题
      } else if (type == 6) {
        return $t("Pub_LP");
        // 学习地图
      } else if (type == 8) {
        return $t("cm_package");
        // 培训包
      } else if (type == 9) {
        return $t("cm_newstaff");
        // 新人培训
      } else if (type == 10) {
        return $t("Sys_Exam");
        // 考试
      } else if (type == 11) {
        return $t("task_supervision");
        // 任务督导
      } else if (type == 12) {
        return $t("cm_activity");
        // 活动
      } else if (type == 13) {
        return $t("demand_collection");
        // 需求征集
      } else if (type == 14) {
        return $t("cm_project");
        // 培训实施
      } else if (type == 15) {
        return $t("cm_contest");
        // 答题竞赛
      } else if (type == 17) {
        return $t("face_lecturer");
        // 学员面授
      } else if (type == 18) {
        return $t("lecturer_face");
        // 讲师面授
      } else if (type == 19) {
        return $t("attendance_face");
        // 考勤面授
      } else if (type == 20) {
        return $t("CM_Live");
        // 直播
      } else if (type == 21) {
        return $t("exam_approval");
        // 考试批阅
      } else if (type == 16) {
        return $t("CM_CT");
        // 面授培训
      } else if (type == -99) {
        return $t("cm_bbs");
        // 学习社区
      } else if (type == 23) {
        return $t("make_up_exam");
        // 补考
      } else if (type == 24) {
        return $t("AI_Practice");
        // AI对练
      }else if (type == 26) {
        return $t("mine.homework_approval");
        // 作业批阅
      }
    };

    const selectAll = () => {
      state.isAll = !state.isAll;
      state.dataList.map((item) => {
        if (state.isAll) {
          item.isDel = true;
        } else {
          item.isDel = false;
        }
      });
    };

    const toDel = () => {
      let ids = [];
      state.dataList.map((item) => {
        if (item.isDel) {
          ids.push(item.messageId);
        }
      });
      if (ids.length) {
        Modal.confirm({
          title: () => $t("mine.delete_msg_tip"),
          // 确定要删除当前选中的消息吗？
          icon: () => createVNode(ExclamationCircleOutlined),
          okType: "danger",
          onOk() {
            batchDelMsg({ ids: ids }).then((data) => {
              if (data.ret == 0) {
                proxy.$message.success($t("CM_SuccessfullyDelete"));
                // 删除成功！
                state.isEdit = false;
                state.isAll = false;
                reload();
              }
            });
          },
        });
      } else {
        proxy.$message.warning(
          $t("mine.please_choose_to_delete_message_content")
        );
        // 请选择要删除的消息内容
      }
    };

    let urlStr = "";
    const clickJump = (item) => {
      let data = item.resourceData.split("|");
      let id = data[0];
      let detailId = data[1] || 0;
      if (item.readTime == 0) {
        msgRead({ ids: [item.messageId] }).then((res) => {
          if (res.ret === 0) {
            item.readTime = 1;
            if (res.data === 0) store.dispatch("setMsgCount", res.data);
          }
        });
      }
      switch (item.taskType) {
        case 0: //培训项目
          urlStr = `/project/detail?id=${id}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 1: //培训项目
          urlStr = `/project/detail?id=${id}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 2: //每日一练
          if (detailId > 0) {
            urlStr = `/exam/detail?id=${id}&did=${detailId}`;
            if (store.getters.companyInfo.useNewWindow == 1) {
              window.open(urlStr + "&ddtab=true");
            } else {
              window.location.href = urlStr;
            }
          }
          break;
        case 3: //知识
          urlStr = `/doc/detail?id=${id}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 4: //课程
          urlStr = `/course/detail?id=${id}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 5: //课程专题
          urlStr = `/subject/detail?id=${id}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 6: //学习地图
          urlStr = `/map/detail?id=${id}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 8: //培训包
          urlStr = `/package/detail?id=${id}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 9: //新人培训
          urlStr = `/newstaff/detail?id=${id}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 10: //考试
          urlStr = `/exam/detail?id=${id}&did=${detailId}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 11: //任务督导
          urlStr = `/project/detail?id=${id}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 12: //活动
          getActivityInfo(id).then((data) => {
            if (data.ret == 0) {
              detailId = data.data.detailId;
              let resourceId = data.data.resourceId;
              let progress = data.data.progress;
              let taskName = data.data.taskName;
              urlStr = `/questionnaire/detail?source=msg&id=${id}&did=${detailId}&resourceId=${resourceId}&progress=${progress}&evalType=0&name=${taskName}`;
              if (store.getters.companyInfo.useNewWindow == 1) {
                window.open(urlStr + "&ddtab=true");
              } else {
                window.location.href = urlStr;
              }
            }
          });
          break;
        case 13: //需求征集
          urlStr = `/mine#demand`;
          ls.set("msgDemandId", id);
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "?ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 14: //培训实施
          urlStr = `/project/detail?id=${id}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        // case 17: //学员面授
        //   window.open(`/project/detail?id=${id}`);
        //   break;
        // case 18: //讲师面授
        //   window.open(`/project/detail?id=${id}`);
        //   break;
        // case 19: //考勤面授
        //   window.open(`/project/detail?id=${id}`);
        //   break;
        case 20: //直播
          if (detailId > 0) {
            urlStr = `/live/detail?id=${id}&did=${detailId}`;
            if (store.getters.companyInfo.useNewWindow == 1) {
              window.open(urlStr + "&ddtab=true");
            } else {
              window.location.href = urlStr;
            }
          }
          break;
        case 21: //考试批阅
          urlStr = `/exam/review/edit?taskId=${id}&detailId=${detailId}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 16: //面授管理
          urlStr = `/train/detail?id=${id}&did=${detailId}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case -99: // 学习社区
          if (["-3", "-4", "-5"].includes(data[2])) {
            // 圈子详情
            urlStr = `/bbs/group?groupId=${data[0]}`;
          } else if (["-1", "-6", "-7", "-8", "-9"].includes(data[2])) {
            // 帖子详情
            urlStr = `/bbs/detail?groupId=${data[0]}&subjectId=${data[1]}`;
          } else if (data[2] === "-2") {
            // 邀请详情
            urlStr = `/bbs/invite?groupId=${data[0]}`;
          }
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 23: //考试
          urlStr = `/exam/detail?id=${id}&reexamId=${detailId}&taskType=23`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 24: //AI对练
          proxy.$message.warning($t("AI_Practice_tips"));
          break;
        case 26: //作业批阅
          let name = item.text.split('您批阅的')[1].split('作业')[0];
          let showType = 'homework';
          if(name!=undefined&&name!=null&&name.length>0){
            showType='teaching';
          }else{
            name = item.text.split('《')[1].split('》')[0];
          }
          urlStr = `/mine?showtype=${showType}`;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
      }
    };

    getList();

    const reg = /\$\[[^\]]*\]|[^\$\[]+/g;
    const openDataRender = (text) => {
      return text.match(reg) || [];
    };

    return {
      ...toRefs(state),
      changePage,
      showSizeChange,
      getResourceName,
      clickJump,
      selectAll,
      toDel,
      moment,
      openDataRender,
    };
  },
};
</script>

<style lang="less" scoped>
.msg-top {
  height: 32px;
}
.msg-wrap {
  min-height: 800px;
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  border-radius: 6px;
  padding: 24px 27px;
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    font-size: 16px;
    .bread {
      padding: 2px 6px;
      border-radius: 2px;
      display: inline-block;
      margin-right: 60px;
      .totals {
        font-size: 14px;
        margin-left: 10px;
        color: #aaa;
        .blue {
          color: @color-theme;
        }
      }
    }
    .funcs span {
      color: @color-theme;
      cursor: pointer;
      margin-left: 15px;
      font-size: 14px;
    }
  }
  .list {
    min-height: 500px;
    .item {
      border: 1px solid #ebedf3;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 6px;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        .title {
          font-weight: 600;
          .dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #ed4014;
            position: relative;
            top: -10px;
          }
        }
      }
      .date {
        font-size: 12px;
        color: #a1a4b3;
      }
      .detail {
        position: relative;
        font-size: 14px;
        margin-top: 24px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .content {
          max-width: 1000px;
          padding-top: 3px;
          display: inline-block;
        }
        a {
          position: absolute;
          bottom: 0;
          right: 0;
          color: @color-theme;
        }
      }
    }
  }
}
</style>
